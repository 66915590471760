import {
	DefaultUrlSerializer,
	UrlSerializer,
	UrlTree
} from '@angular/router';

export let outlets = [
	{
		'name': 'portfolio',
		'routes': [
			'/chase',
			'/2dmapgenerator'
		]
	}
];

export class CleanAuxUrlSerializer implements UrlSerializer {
	private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

	parse(url: string): UrlTree {
		outlets.forEach(outlet => {
			if (!outlet.routes.includes(url)) {
				return;
			}

			url = `/(${outlet.name}:${url.replace('/', '')})`;
		});

		return this._defaultUrlSerializer.parse(url);
	}

	serialize(tree: UrlTree): string {
		let url = this._defaultUrlSerializer.serialize(tree);
		outlets.forEach(outlet => {
			const reg = new RegExp('\\(' + outlet.name + ':([^\/]*)\\)');
			url = url.replace(reg, '$1');
		});

		return url;
	}
}
