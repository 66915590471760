import { Component, OnInit, HostListener, HostBinding } from '@angular/core';

@Component({
	selector: 'app-fill-screen',
	templateUrl: './fill-screen.component.html',
	styleUrls: ['./fill-screen.component.scss']
})
export class FillScreenComponent implements OnInit {

	@HostBinding('style.height')
	hostHeight: string;

	constructor() { }

	ngOnInit() {
		this.refreshHeight();
	}

	@HostListener('window:orientationchange')
	private onOrientationChange() {
		setTimeout(() => {
			this.refreshHeight();
		}, 300);
	}

	private refreshHeight() {
		const height = window.innerHeight;
		this.hostHeight = `${height}px`;
	}
}
