import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import smoothscroll from "smoothscroll-polyfill";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
	constructor(private location: Location) {}

	ngOnInit() {
		smoothscroll.polyfill();
	}

	scrollToWork(event: Event) {
		const options: ScrollIntoViewOptions = {
			behavior: "smooth",
			block: "start",
			inline: "nearest"
		};

		document.querySelector("#work").scrollIntoView(options);

		event.preventDefault();
	}
}
