import { CleanAuxUrlSerializer } from './StandardUrlSerializer';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSerializer, ExtraOptions } from '@angular/router';
import { HomeComponent } from '../home/home.component';
import { WorkModalComponent } from '../work/work-modal/work-modal.component';

const routes: Routes = [
	{
		path: '',
		component: HomeComponent
	},
	{
		path: ':uri',
		component: WorkModalComponent,
		outlet: 'portfolio'
	}
];

const routerSettings: ExtraOptions = {
	anchorScrolling: 'enabled',
};

@NgModule({
	imports: [RouterModule.forRoot(routes, routerSettings)],
	exports: [RouterModule],
	providers: [{
		provide: UrlSerializer,
		useClass: CleanAuxUrlSerializer
	}]
})

export class AppRoutingModule { }
